<template>
  <Layout>
    <template v-if="this.$route.name == 'add-forum-tool'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data">
              <div class="row">
                <b-form-group class="col-12">
                  <label for="input-multi"
                    >Forum Name <span style="color: red">*</span></label
                  >
                  <b-form-select
                    id="input-multi"
                    v-model="form.forum_id"
                    :options="forumLists"
                    value-field="id"
                    text-field="title"
                    :class="{
                      'is-invalid': submitted && $v.form.forum_id.$error,
                    }"
                  >
                  </b-form-select>
                  <div
                    v-if="submitted && !$v.form.forum_id.required"
                    class="invalid-feedback"
                  >
                    Select Forum List.
                  </div>
                </b-form-group>
                <b-form-group class="col-12">
                  <label for="input-multi"
                    >Domain Name <span style="color: red">*</span></label
                  >
                  <b-input-group append="mymedisage.com">
                    <b-form-input
                      v-model="form.domain_name"
                      :class="{
                        'is-invalid': submitted && $v.form.domain_name.$error,
                      }"
                    >
                    </b-form-input>
                  </b-input-group>
                  <div
                    v-if="submitted && !$v.form.domain_name.required"
                    class="invalid-feedback"
                  >
                    Select Forum List.
                  </div>
                </b-form-group>
                <b-form-group
                  class="col-6"
                  label="Forum Logo [Upload Max File Size : 2MB]"
                  label-for="input-12"
                >
                  <b-form-file
                    accept="image/*"
                    placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'logo_image')"
                    ref="logo_image"
                  ></b-form-file>
                  <template
                    v-if="$route.name == 'edit-forum-tool' && edit.logo_image"
                  >
                    <img
                      :src="edit.logo_image"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="logo_image">
                    <img :src="logo_image" width="128px" height="128px" />
                  </template>
                </b-form-group>
                <b-form-group
                  class="col-6"
                  label="Forum Banner Image [Upload Max File Size : 2MB]"
                  label-for="input-12"
                >
                  <b-form-file
                    accept="image/*"
                    placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'banner_image')"
                    ref="banner_image"
                  ></b-form-file>
                  <template
                    v-if="$route.name == 'edit-forum-tool' && edit.banner_image"
                  >
                    <img
                      :src="edit.banner_image"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                  <template v-if="banner_image">
                    <img :src="banner_image" width="128px" height="128px" />
                  </template>
                </b-form-group>
                <b-form-group
                  label="Field Rep Channel"
                  label-for="field_rep_channel"
                  class="col-6"
                >
                  <multiselect
                    id="field_rep_channel"
                    v-model="form.field_rep_channels"
                    :options="transformedOptions"
                    :multiple="true"
                    placeholder="Select Field Rep Channel"
                    track-by="value"
                    label="text"
                  >
                  </multiselect>
                </b-form-group>
              </div>
              <b-button
                type="submit"
                variant="primary"
                class="mr-2"
                v-if="$route.name == 'edit-forum-tool'"
                @click.prevent="updateData($route.params.id)"
                >Update Data</b-button
              >
              <b-button
                type="submit"
                variant="primary"
                class="mr-2"
                v-else-if="$route.name == 'add-forum-tool'"
                @click.prevent="submitData"
                >Save Data</b-button
              >
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
  
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import forumToolMixin from "../../../mixins/ModuleJs/forum-tool";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

export default {
  mixins: [MixinRequest, forumToolMixin],
  data() {
    return {
      submitted: false,
      title1: "Create Forum Tool",
      title2: "Edit Forum Tool",
      items: [
        {
          text: "Back",
          href: "/forum-tool",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      forum_id: { required },
      domain_name: { required },
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect
  },
};
</script>
  